import styled from 'styled-components'
import { gray } from '../../../../styles/colors'
import { device } from '../../../../styles/breakpoints'

export const OQueEstaoFalandoSection = styled.section`
  .react-multi-carousel-track {
    padding: 30px 0 0px;
  }

  .react-multi-carousel-dot-list {
    display: none;
  }
`

export const Card = styled.div`
  background-color: ${gray[400]};
  border: 1px solid #DEDFE4;
  border-radius: 8px;
  width: 264px;
  height: 220px;

  @media ${device.tablet} {
    width: 225px;
    height: 220px;
  }

  @media ${device.desktopLG} {
    width: 300px;
    height: 218px;
  }

  @media ${device.desktopXL} {
    width: 360px;
    height: 188px;
  }

  .gatsby-image-wrapper {
    max-width: 40px !important;
    max-height: 40px !important;
  }
`
