import React from 'react'
import IntercoOrangeIcon from '../../../../components/MarkdownIcon/InterCoIcons'
import QuemPodeParticiparJson from '../../assets/data/quem-pode-participar.json'
import { Card } from './style'

type QuemPodeParticiparType = {
  id: number;
  icon: string;
  description: string;
  title: string;
}

const QuemPodeParticipar = () => {
  return (
    <section id='requisitos' className='py-5'>
      <div className='container'>
        <div className='row justify-content-center justify-content-md-between align-items-center'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-center mb-3'>
              Quem pode participar?
            </h2>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--500 text-center mb-5'>
              Buscamos estudantes apaixonados por tecnologia, protagonistas do fazer acontecer
              <span className='d-lg-block'>e com gás para construir projetos de forma colaborativa.</span>
            </p>
          </div>

          <div className='col-12 d-xl-flex flex-wrap justify-content-xl-center'>
            {QuemPodeParticiparJson.map((item: QuemPodeParticiparType) => (
              <Card key={item.id}>
                <div className='d-flex align-items-md-center'>
                  <div>
                    <IntercoOrangeIcon icon={item.icon} size='MD' color='#FF7A00' />
                  </div>

                  <div className='ml-3 ml-lg-4 d-md-flex align-items-md-center'>
                    <p
                      className='fs-18 lh-20 text-grayscale--500 fw-700 mb-2 mb-md-0 title'
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 mb-md-0 text-grayscale--500 description'>
                      {item.description}
                    </p>
                  </div>
                </div>
                { item.id !== 6 && <hr className='' /> }
              </Card>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default QuemPodeParticipar
