import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      photoArthur: imageSharp(fluid: {originalName: { regex: "/depoimentos-arthur/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoAmanda: imageSharp(fluid: {originalName: { regex: "/depoimentos-amanda/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      photoVitor: imageSharp(fluid: {originalName: { regex: "/depoimentos-vitor/" }}) {
        fluid(maxWidth: 24, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
