import React from 'react'

// Components
import OrangeIcon from '../../../../components/MarkdownIcon/InterCoIcons'
import DefaultCarousel from '../../../../components/Carousels/DefaultCarousel'
import SuaJornadaComecaJSON from '../../assets/data/suaJornadaComeca.json'

import { Section, Card } from './style'

type SuaJornadaComecaData = {
  description: string;
  icon: string;
}

const SuaJornadaComecaAqui = () => {
  return (
    <Section id='jornada' className='py-5 bg-orange--super-light d-flex align-items-end align-items-lg-center'>
      <div className='container pb-lg-2 pt-lg-3 py-xl-5'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 px-0'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 text-white mb-3'>
              Sua jornada começa aqui
            </h2>
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-21 text-white mb-0'>
              Dê start na sua carreira de sucesso. No Inter, você conta com um espaço movido a inovação e cheio de oportunidades para se desenvolver.
            </p>
          </div>
          <div className='col-12 col-md-6 offset-lg-1'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 43 }}
              md={{ items: 1, partialVisibilityGutter: 86 }}
              lg={{ items: 1, partialVisibilityGutter: 140 }}
              xl={{ items: 1, partialVisibilityGutter: 270 }}
            >
              {SuaJornadaComecaJSON.map((item: SuaJornadaComecaData, index: number) => (
                <div key={index} className='col-12 px-md-2'>
                  <Card className='rounded-16 bg-white px-4 px-md-3 px-lg-4 pt-4 mb-3'>
                    <OrangeIcon size='LG' color='#ff7a00' icon={item.icon} />
                    <p className='fs-18 lh-22 text-grayscale--500 fw-600 mt-3' dangerouslySetInnerHTML={{ __html: item.description }} />
                  </Card>
                </div>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SuaJornadaComecaAqui
