import React from 'react'
import Img from 'gatsby-image'
import DefaultCarousel from '../../../../components/Carousels/DefaultCarousel'
import useWidth from '../../../../hooks/useWidth'
import { WIDTH_XL } from '../../../../utils/breakpoints'

// PageContext
import usePageQuery from '../../pageQuery'

// Data
import DepoimentosJson from '../../assets/data/depoimentos.json'

import { OQueEstaoFalandoSection, Card } from './style'

interface IDepoimentos {
  photo: string;
  name: string;
  comment: string;
  cargo: string;
}

const Depoimentos = () => {
  const windowWidth = useWidth(100)
  const data = usePageQuery()

  return (
    <OQueEstaoFalandoSection id='depoimentos' className='py-5 bg-white'>
      <div className='row align-items-center mr-0'>
        <div className='col-12'>
          <div className='d-md-flex flex-column justify-content-center'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-center'>
              Depoimentos de nossos Sangue Laranja
            </h2>

            {windowWidth >= WIDTH_XL ? (
              <div className='col-xl-12 d-xl-flex justify-content-xl-center mt-4 py-2'>
                {DepoimentosJson.map((card: IDepoimentos) => (
                  <div key={card.photo} className='px-2'>
                    <Card className='d-flex flex-column justify-content-between p-3'>
                      <div>
                        <div className='d-flex align-items-center'>
                          <Img fluid={data[card.photo].fluid} alt='Foto de perfil' />
                          <div className='d-flex flex-column ml-3 mt-xl-3'>
                            <p className='fs-14 lh-16 fw-700 text-grayscale--500 mb-1'>{card.name}</p>
                            <p className='fs-12 lh-14 text-grayscale--500 mt-0'>{card.cargo}</p>
                          </div>
                        </div>
                        <div className='mt-2'>
                          <p className='fs-12 lh-14 fs-lg-14 lh-lg-16 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: card.comment }} />
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            ) : (
              <div className='col-12'>
                <DefaultCarousel
                  sm={{ items: 1, partialVisibilityGutter: 70 }}
                  md={{ items: 3, partialVisibilityGutter: 5 }}
                  lg={{ items: 3, partialVisibilityGutter: 5 }}
                  xl={{ items: 3, partialVisibilityGutter: 80 }}
                  removeArrowOnDeviceType={[ 'mobile' ]}
                >
                  {DepoimentosJson.map((card: IDepoimentos) => (
                    <div key={card.photo} className='px-2'>
                      <Card className='d-flex flex-column justify-content-between p-3'>
                        <div>
                          <div className='d-flex align-items-center'>
                            <Img fluid={data[card.photo].fluid} alt='Foto de perfil' />
                            <div className='d-flex flex-column ml-3 mt-3'>
                              <p className='fs-14 lh-16 fw-700 text-grayscale--500 mb-1'>{card.name}</p>
                              <p className='fs-12 lh-14 text-grayscale--500 mt-0'>{card.cargo}</p>
                            </div>
                          </div>
                          <div className='mt-2'>
                            <p className='fs-12 lh-14 fs-lg-14 lh-lg-16 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: card.comment }} />
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))}
                </DefaultCarousel>
              </div>
            )}
          </div>
        </div>
      </div>
    </OQueEstaoFalandoSection>
  )
}

export default Depoimentos
