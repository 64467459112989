import styled from 'styled-components'
import { device } from '../../../../styles/breakpoints'

export const Card = styled.div`
  padding: 16px;

  .title {
    @media ${device.tablet} {
      width: 132px;
      margin-right: 50px;
    }

    @media ${device.desktopLG} {
      width: 165px;
    }
  }

  .description {
    @media ${device.tablet} {
      width: 470px;
    }

    @media ${device.desktopLG} {
      width: 580px;
    }

    @media ${device.desktopXL} {
      width: 675px;
    }
  }
`
