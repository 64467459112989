import styled from 'styled-components'
import { breakpoints } from '../../../../styles/breakpoints'

export const Section = styled.section`
  background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/dobra-video-mobile/image.webp);
  background-repeat: no-repeat;
  background-size: cover;

  padding-top: 111px !important;
  height: 500px;

  @media (min-width: ${breakpoints.md}) {
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/dobra-video-1440/image.webp);
    background-repeat: no-repeat;
    background-size: 440px;
    background-color: #F5F6FA;

    padding-top: 102px !important;
    height: 256px;
  }

  @media (min-width: ${breakpoints.lg}) {
    background-size: 580px;
    padding-top: 140px !important;
    height: 339px;
  }

  @media (min-width: ${breakpoints.xl}) {
    background-size: 690px;
    padding-top: 160px !important;
    height: 404px;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    background-size: 940px;
    padding-top: 211px !important;
    height: 530px;
  }

  img {
    width: 80px;
    height: 80px;
    left: 140px;
    top: 111px;
  }
`
