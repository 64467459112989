import styled from 'styled-components'
import { breakpoints } from '../../../../styles/breakpoints'

export const CardContainer = styled.div`
  background: white;
  padding: 24px;
  align-items: center;
  border-radius: 16px;
  border-right: 4px solid #161616;
  border-bottom: 4px solid #161616;

  height: 118px;

  @media (min-width: ${breakpoints.md}) {
    width: 156px;
    height: 134px;
    padding: 16px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 216px;
    height: 122px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 264px;
    height: 132px;
  }
`
