import styled from 'styled-components'
import { breakpoints } from '../../../../styles/breakpoints'

export const Section = styled.section`
  min-height: 630px;
  display: flex;
  align-items: center;


  /* @media (min-width: ${breakpoints.md}) {
    min-height: 352px
  } */
  @media (min-width: ${breakpoints.lg}) {
    min-height: 388px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 448px;
  }
`
export const Card = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  width: 100%;
  height: auto;
  padding: 24px;
  border-radius: 8px;
  border: 4px solid #FF9D42;

  @media (min-width: ${breakpoints.md}) {
    width: 336px;
    height: 266px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 456px;
    height: 250px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 552px;
    height: 255px;
  }
`

export const CardsContainer = styled.div`
  gap: 24px;
`
