import React from 'react'
import Layout from '../../components/Layout'

import Hero from './sections/header/_index'
import SuaJornadaComecaAqui from './sections/sua-jornada-comeca/_index'
import AreasDeAtuacao from './sections/areas-atuacao/_index'
import Beneficios from './sections/beneficios/_index'
import QuemPodeParticipar from './sections/quem-pode-participar/_index'
import EtapasDoPrograma from './sections/etapas/_index'
import Depoimentos from './sections/depoimentos/_index'
import ConhecaOInter from './sections/conheca-o-inter/_index'
import FAQ from './sections/faq/_index'

import { Wrapper } from './style'
import pageContext from './pageContext.json'

const GoTech = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext} isGoTech isHome={false}>
        <Hero />
        <SuaJornadaComecaAqui />
        <AreasDeAtuacao />
        <Beneficios />
        <QuemPodeParticipar />
        <EtapasDoPrograma />
        <Depoimentos />
        <ConhecaOInter />
        <FAQ />
      </Layout>
    </Wrapper>
  )
}

export default GoTech
