import React from 'react'
import useDataLayer from '../../../../hooks/useDataLayer/dataLayerBody'
import OpenVideo from '../../../../components/OpenVideo'

import Play from '../../assets/images/play-white.png'

import { Section } from './style'

const ConhecaOInter = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='conheca-inter' className='pb-5'>
      <div className='container h-100'>
        <div className='row justify-content-center justify-content-md-between align-items-center align-content-between h-100'>
          <div className='col-12 col-md-5 col-xl-3 text-center'>
            <OpenVideo
              link='https://www.youtube.com/embed/jktMq2mviGw?si=52l6W8U-yhmcy_UJ'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_08',
                  section_name: 'Conheça um pouco do prédio do Inter',
                  element_action: 'click video',
                  element_name: 'null',
                  redirect_url: 'https://www.youtube.com/embed/jktMq2mviGw?si=52l6W8U-yhmcy_UJ',
                })
              }}
            >
              <img
                src={Play}
                alt='Assista ao vídeo'
                className='cursor-pointer'
                title='Assista ao vídeo'
              />
            </OpenVideo>
          </div>
          <div className='col-12 col-md-5 px-md-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-3'>
              Conheça um pouco do prédio do Inter
            </h2>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ConhecaOInter
