import styled from 'styled-components'
import { orange } from '../../../../styles/colors'
import breakpoints from '../../../../styles/breakpoints'
import BgSM from '../../assets/images/background-hero-sm.png'
import BgMD from '../../assets/images/background-hero-md.png'
import BgLG from '../../assets/images/background-hero-lg.png'
import BgXL from '../../assets/images/background-hero-xl.png'

export const ContentHeader = styled.section`
  background-color: ${orange.extra};
  background-image: url(${BgSM});
  background-repeat: no-repeat;

  @media (min-width: ${breakpoints.md}) {
    background-image: url(${BgMD});
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 35px;
  }

  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${BgLG});
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 35px;
    background-size: 431px;
    height: 600px;
  }

  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${BgXL});
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 0;
    background-size: 700px;
    height: 700px;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    background-image: url(${BgXL});
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 0;
    background-size: 900px;
    height: 700px;
  }

  a, button {
    height: 48px;
  }
`
