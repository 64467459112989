import React from 'react'
import OrangeIcon from '../../../../components/MarkdownIcon/InterCoIcons'
import ImageWebp from '../../../../components/ImageWebp'
import useDataLayer from '../../../../hooks/useDataLayer/dataLayerBody'
import { orange } from '../../../../styles/colors'
import { CardContainer } from './style'

import BeneficiosJson from '../../assets/data/beneficios.json'

interface IAdvantagesTypes {
  icon: string;
  text: string;
}

const Beneficios = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='beneficios' className='bg-gray--400 py-5'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 mb-4 mb-md-0'>
            <ImageWebp
              arrayNumbers={[ 312, 333, 374, 390 ]}
              arrayNumbersHeight={[ 364, 390, 434, 456 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/go-tech-hero-2/image.png'
              altDescription='Mulher'
            />
          </div>

          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-3 mb-md-0'>
              Benefícios
            </h2>
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-3'>
              Durante seu estágio você poderá aproveitar os benefícios de ser um Sangue Laranja:
            </p>

            <div className='row'>
              {BeneficiosJson.map((advantages: IAdvantagesTypes) => (
                <div className='col-12 col-md-6 mt-4' key={advantages.icon}>
                  <CardContainer>
                    <div className='d-flex mb-3'>
                      <OrangeIcon icon={advantages.icon} size='MD' color={orange.extra} />
                    </div>
                    <span
                      className='fs-16 lh-19 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 text-grayscale--500 fw-700'
                      dangerouslySetInnerHTML={{ __html: advantages.text }}
                    />
                  </CardContainer>
                </div>
              ))}
            </div>

            <div className='mt-4 py-2'>
              <a
                href='https://matchbox.digital/estagiointergotech2024-inscricao'
                target='blank'
                className='btn btn--lg btn-orange--extra text-white rounded-8 fs-14 fw-600 text-none mb-0 mw-100'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    section_name: 'Benefícios',
                    element_action: 'click button',
                    element_name: 'Quero me cadastrar',
                    redirect_url: 'https://matchbox.digital/estagiointergotech2024-inscricao',
                  })
                }}
              >
                Quero me cadastrar
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Beneficios
