import React from 'react'

import IntercoOrangeIcon from '../../../../components/MarkdownIcon/InterCoIcons'
import { grayscale } from '../../../../styles/colors'

import AreasAtuacaoData from '../../assets/data/areas-atuacao.json'

import { Section, Card, CardsContainer } from './style'

type theInterSimplifyProps = {
  icon: string;
  title: string;
  description: string;
}

const AreasDeAtuacao = () => {
  return (
    <Section id='area-atuacao' className='py-5 bg-white'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 text-center fw-600 mb-md-3'>
              Áreas de atuação
            </h2>
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-21 text-grayscale--400 text-center mb-md-4 pb-lg-2'>
              O Go Tech é a sua oportunidade de atuar em diversas áreas do nosso
              negócio, desde desenvolvimento e prototipação de telas até análise
              de dados, mapeamento e automatização de processos.
            </p>
          </div>

          <CardsContainer className='d-flex flex-wrap justify-content-center'>
            {AreasAtuacaoData.map((item: theInterSimplifyProps) => (
              <div key={item.icon}>
                <Card>
                  <div>
                    <IntercoOrangeIcon
                      size='MD'
                      color={grayscale['400']}
                      icon={item.icon}
                      width={37}
                      height={37}
                    />
                    <h3 className='fs-20 lh-25 fs-xl-20 lh-xl-22 fw-600 text-grayscale--500 mt-3'>
                      {item.title}
                    </h3>
                    <p className='fs-16 lh-16 fs-lg-16 lh-lh-20 fs-xl-18 lh-xl-22 text-grayscale--400'>
                      {item.description}
                    </p>
                  </div>
                </Card>
              </div>
            ))}
          </CardsContainer>
        </div>
      </div>
    </Section>
  )
}

export default AreasDeAtuacao
