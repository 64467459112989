import styled from 'styled-components'
import { device } from '../../../../styles/breakpoints'
import { orange } from '../../../../styles/colors'

export const Section = styled.section`
  background-color: ${orange.extra};

  .react-multiple-carousel__arrow--left {
    left: 0;

    @media ${device.tablet} {
      left: -7px;
    }
    @media ${device.desktopLG} {
      left: -3px;
    }
  }

  .react-multi-carousel-dot-list .react-multi-carousel-dot button {
    background-color: #FFFFFF;
  }

  .fill-orange--base {
    fill: white;
  }

  .react-multiple-carousel__arrow--right {
    right: 0;

    @media ${device.tablet} {
      right: -2px;
    }
    @media ${device.desktopLG} {
      right: 3px;
    }
    @media (min-width: 1600px) {
      right: 122px;
    }
  }
`

export const Card = styled.div`
  min-height: 169px;
  border-radius: 16px !important;
  border-bottom: 4px solid #000;
  border-left: 4px solid #000;

  @media ${device.tablet} {
    min-height: 154px
  }

  @media ${device.desktopLG} {
    min-height: 169px;
  }

  @media ${device.desktopXL} {
    width: 264px;
    min-height: 169px;
  }
`
